import React, { useEffect } from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import StarIcon from "../assets/svg-icons/star.svg";
import Tags from "./Tags";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const PostItem = props => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (i18n.language === "ar") {
      import("moment/locale/ar").then(() => {
        // moment.locale("ar"); // Remove this if not using moment
      });
    } else {
      // moment.locale(); // Remove this if not using moment
    }
  }, [i18n.language]);

  const { post } = props;

  return (
    <article className="post-card flex">
      {post.frontmatter.featuredImage && (
        <Link hrefLang={i18n.language} to={post.fields.slug} className="post-img-wrap">
          <GatsbyImage
            image={getImage(post.frontmatter.featuredImage)}
            alt={post.frontmatter.title}
            style={{ height: "100%", position: "unset" }}
          />
        </Link>
      )}
      <div className="post-info-wrap">
        <div className="flex post-top-meta">
          {post.frontmatter.tags && post.frontmatter.tags.length > 0 && (
            <Tags tags={[post.frontmatter.tags[0]]} t={t} />
          )}
          {post.frontmatter.featured && (
            <div
              className="featured-icon"
              aria-labelledby="Featured post icon"
              role="img"
            >
              <StarIcon />
            </div>
          )}
        </div>
        <h2 className="h3 post-title">
          <Link hrefLang={i18n.language} to={post.fields.slug}><Trans>{post.frontmatter.title}</Trans></Link>
        </h2>
        <div className="post-excerpt"><Trans>{post.excerpt}</Trans></div>
        {/* Remove or comment out the post-meta and date time */}
        {/* <div className="post-meta">
          <time className="post-date" dateTime={post.frontmatter.date}>
            {t(formattedDate)}
          </time>
        </div> */}
      </div>
    </article>
  );
};

export default PostItem;
