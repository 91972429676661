import React from "react"
import { Link } from "gatsby"
import { useTranslation } from "gatsby-plugin-react-i18next"

const Tags = ({ tags  }) => {
  const {t,i18n} = useTranslation()
  return (
    <div className="tag-wrap">
      {tags.map(
        (tag, index) =>
          tag !== null && (
            <Link 
            hrefLang={i18n.language}
              to={`/tag${tag.fields.slug}`}
              style={{
                backgroundColor:
                  tag.color !== "" || tag.color !== null
                    ? tag.color
                    : "inherit",
              }}
              key={index}
            >
              {t(tag.name)}
            </Link>
          )
      )}
    </div>
  )
}

export default Tags
